import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c(VCard,{staticClass:"mx-auto"},[_c(VTabs,{attrs:{"show-arrows":"","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,[_vm._v(" 1 Profile")]),_c(VTab,[_vm._v(" 2 Files")]),_c(VTab,[_vm._v(" 3 Privacy")]),_c(VTab,[_vm._v(" 4 Features")]),_c(VTab,[_vm._v(" 5 Settings")]),_c(VTab,[_vm._v(" 6 Backup/Restore")])],1),_c(VContainer,[_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c('user-profile')],1),_c(VTabItem,[_c('user-files')],1),_c(VTabItem,[_c('user-privacy')],1),_c(VTabItem,[_c('user-features')],1),_c(VTabItem,[_c('user-setting',{staticClass:"mt-3"})],1),_c(VTabItem,[_c('backup-restore')],1)],1)],1),_c('div',{staticClass:"d-flex justify-center flex-wrap py-2"},[_c('NavButtonAdmin',{attrs:{"btn-text":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Admin ")]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }