<template>
  <v-main>
    <v-container>
      <v-card class="mx-auto">
        <v-tabs show-arrows v-model="tab" grow>
          <v-tab> 1 Profile</v-tab>
          <v-tab> 2 Files</v-tab>
          <v-tab> 3 Privacy</v-tab>
          <v-tab> 4 Features</v-tab>
          <v-tab> 5 Settings</v-tab>
          <v-tab> 6 Backup/Restore</v-tab>
        </v-tabs>
        <v-container>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <user-profile></user-profile>
            </v-tab-item>
            <v-tab-item>
              <user-files></user-files>
            </v-tab-item>
            <v-tab-item>
              <user-privacy></user-privacy>
            </v-tab-item>
            <v-tab-item>
              <user-features></user-features>
            </v-tab-item>
            <v-tab-item>
              <user-setting class="mt-3"></user-setting>
            </v-tab-item>
            <v-tab-item>
              <backup-restore></backup-restore>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
        <div class="d-flex justify-center flex-wrap py-2">
          <NavButtonAdmin :btn-text="false">
            <template #label>
              Admin
            </template>
          </NavButtonAdmin>
          <!-- <v-btn
            class="ml-3 mt-2 mb-2"
            v-if="$store.getters.isAdmin"
            color="info"
            large
            outlined
            @click="$router.push('/admin/dashboard')"
            >Admin <v-icon>mdi-account-cog</v-icon></v-btn
          > -->
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
export default {
  metaInfo: {
    title: 'Profile'
  },
  components: {
    UserFeatures: () => import('@/components/User/UserFeatures'),
    UserFiles: () => import('@/components/User/UserFiles'),
    UserSetting: () => import('@/components/User/UserSetting'),
    UserProfile: () => import('@/components/User/UserProfile'),
    BackupRestore: () => import('@/components/User/BackupRestore'),
    UserPrivacy: () => import('@/components/User/UserPrivacy'),
    NavButtonAdmin: () => import('../components/NavButtonAdmin.vue')
  },
  data: () => ({
    currentTab: 0
  }),
  computed: {
    tab: {
      get() {
        return this.currentTab
      },
      set(val) {
        this.currentTab = val
        let newPath = `${this.$route.path}?tab=${val + 1}`
        if (this.$route.fullPath != newPath) {
          this.$router.push(newPath)
        }
      }
    }
  },
  beforeMount() {
    this.checkTab()
  },
  methods: {
    checkTab() {
      let tab = this.$route.query.tab
      tab = Number(tab)
      if (tab) {
        this.tab = tab - 1
      }
    }
  }
}
</script>
